<template>
  <div
    :style="{marginTop, display: (visible ? 'block' : 'none')}"
    class="values-container"
  >
    <div
      v-if="type=='candle'"
      :set="color = currPoint.close < prevPoint.close ? 'red': 'green'"
    >
      <span class="label">O</span>
      <span
        class="value"
        :style="{color}"
      >{{ round(currPoint.open) }}</span>
      <span class="label">H</span>
      <span
        class="value"
        :style="{color}"
      >{{ round(currPoint.high) }}</span>
      <span class="label">L</span>
      <span
        class="value"
        :style="{color}"
      >{{ round(currPoint.low) }}</span>
      <span class="label">C</span>
      <span
        class="value"
        :style="{color}"
      >{{ round(currPoint.close) }}</span>
      <span
        class="value"
        :style="{color}"
      >
        {{ round(currPoint.close - prevPoint.close, true) }}
        ({{ round(((currPoint.close - prevPoint.close) / prevPoint.close) * 100, true) }}%)
      </span>
    </div>

    <div v-if="type=='money'">
      <span class="label">Lực mua</span>
      <span
        class="value"
        style="color: green"
      >{{ round(currPoint.nbf ? currPoint.nbf.value : 0) }}</span>
      <span class="label">Lực bán</span>
      <span
        class="value"
        style="color: red"
      >{{ round(currPoint.nsf ? currPoint.nsf.value : 0) }}</span>
    </div>

    <div v-if="type=='diff'">
      <span class="label">Dòng tiền SI</span>
      <span
        class="value"
        :style="{color: currPoint.value < 0 ? 'red': 'green'}"
      >{{ round(currPoint.value) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marginTop: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    prevData: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    currPoint() {
      return this.data[this.type] || {}
    },
    prevPoint() {
      return this.prevData[this.type] || {}
    },
  },

  methods: {
    round(value, signed) {
      const sign = (signed && value > 0) ? '+' : ''

      return sign + (Number(value)?.toFixed(2) || '0.00')
    },
  },
}
</script>

<style scoped>
  .values-container {
    padding-top: 10px;
    position: absolute;
    left: 40px;
    z-index: 5;
    font-size: 16px;
  }
  .values-container .label {
    margin-right: 5px;
  }
  .values-container .value {
    display: inline-block;
    margin-right: 5px;
    min-width: 42px;
  }
</style>
