<template>
  <div
    class="d-flex"
    style="height: 100%;"
  >
    <ChartValues
      v-for="(marginTop, id) in zoomBtnMarginTop"
      :key="id"
      :style="{marginTop, display: (chartRatios[id] > 0 ? 'block' : 'none')}"
      :type="chartNames[id]"
      :visible="chartRatios[id] > 0"
      :data="currentDataPoint"
      :prev-data="prevDataPoint"
    />

    <div v-if="!isZoomIn">
      <div
        v-for="(marginTop, id) in moveBtnMarginTop"
        :key="id"
        :style="{marginTop}"
        class="d-flex flex-column justify-space-around container-move"
      >
        <v-btn
          v-if="id > 0"
          small
          icon
          @click="move(id, true)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          v-if="id < moveBtnMarginTop.length - 1"
          small
          icon
          @click="move(id, false)"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="!isZoomIn">
      <v-btn
        v-for="(marginTop, id) in zoomBtnMarginTop"
        :key="id"
        :style="{marginTop}"
        class="btn-zoom"
        small
        icon
        @click="zoomIn(id)"
      >
        <v-icon>mdi-arrow-expand</v-icon>
      </v-btn>
    </div>

    <!-- Zoom Out -->
    <v-btn
      v-if="isZoomIn"
      class="btn-zoom"
      small
      icon
      @click="zoomOut"
    >
      <v-icon>mdi-arrow-collapse</v-icon>
    </v-btn>

    <v-range-slider
      ref="slider"
      v-model="sliderValue"
      hide-details
      step="5"
      vertical
      color="black"
      track-color="black"
      track-fill-color="black"
      class="size-slider"
      :disabled="isZoomIn"
    ></v-range-slider>
    <div
      ref="chartContainer"
      class="lw-chart"
    ></div>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts'
import ema from 'exponential-moving-average'

import ChartValues from './ChartValues.vue'

let currentData

const timezoneOffset = 7 * 3600
const timeIndexes = {}

const charts = [null, null, null]
const chartNames = [
  'candle',
  'money',
  'diff',
]
const series = {
  candle: null,
  nbf: null,
  nsf: null,
  diff: null,
}
const seriesMap = { candle: 'candle', money: 'nbf', diff: 'diff' }

const lineWidth = 1

const chartOptions = {
  layout: {
    background: { type: 'solid', color: 'transparent' },
  },
  timeScale: {
    visible: false,
    secondsVisible: false,
    borderVisible: false,
  },
  rightPriceScale: {
    minimumWidth: 70,
  },
  localization: {
    locale: 'vi-VN',
    dateFormat: 'dd MMMM \'yy',
  },
}

const watermark = document.createElement('div')
watermark.style.zIndex = '2'
watermark.style.height = '80px'
watermark.style.width = '390px'
watermark.style.position = 'absolute'
watermark.style.bottom = '50px'
watermark.style.right = '50px'
watermark.style.opacity = '0.2'
watermark.style.backgroundRepeat = 'no-repeat'
watermark.style.backgroundPosition = 'center'
watermark.style.backgroundSize = 'contain'
watermark.style.backgroundImage = `url(${require('@/assets/images/logos/logo.png')})`

const candleSeriesOptions = {
  upColor: 'green',
  downColor: 'red',
  priceLineVisible: false,
}

const nbfSeriesOptions = {
  lineWidth,
  color: 'green',
  priceLineVisible: false,
}

const nsfSeriesOptions = {
  lineWidth,
  color: 'red',
  priceLineVisible: false,
}

const diffSeriesOptions = {
  lineWidth,
  priceLineVisible: false,
  topLineColor: 'green',
  topFillColor1: 'rgba(0,128,0.028)',
  topFillColor2: 'white',
  bottomLineColor: 'red',
  bottomFillColor1: 'white',
  bottomFillColor2: 'rgba(255,0,0.028)',
}

const diffLineOptions = {
  price: 0,
  color: 'black',
  axisLabelVisible: false,
  lineWidth: 1,
}

function getSeriesFromChartId(id) {
  return series[seriesMap[chartNames[id]]]
}

function syncVisible(chartId, range) {
  if (!this.currentTimeIndex) {
    this.currentTimeIndex = range.to
  }
  charts.forEach((chart, idx) => {
    if (chart && chartId !== idx) chart.timeScale().setVisibleLogicalRange(range)
  })
  if (chartId === 0) {
    this.$emit('timeRangeChange', range)
  }
}

function getCrosshairDataPoint(context, param) {
  if (!param.time) {
    return null
  }
  const dataPoint = param.seriesData.get(context)

  return dataPoint || null
}

function syncCrosshair(chartId, param) {
  if (param.time) {
    this.currentTimeIndex = timeIndexes[param.time]
  }

  const dataPoint = getCrosshairDataPoint(getSeriesFromChartId(chartId), param)
  if (dataPoint) {
    charts.forEach((chart, id) => {
      if (chartId !== id) {
        chart.setCrosshairPosition(
          dataPoint.value,
          dataPoint.time,
          getSeriesFromChartId(id),
        )
      }
    })

    return
  }

  charts.forEach(chart => chart.clearCrosshairPosition())
}

const computeData = raw => {
  const {
    t = [], o = [], h = [], l = [], c = [], v = [],
  } = raw

  const candleData = []
  t.forEach((time, idx) => {
    time += timezoneOffset
    candleData.push({
      time,
      open: o[idx],
      low: l[idx],
      high: h[idx],
      close: c[idx],
    })
    timeIndexes[time] = idx
  })

  // bp = C-Li;
  // sp = Hi-C;
  const bp = []
  const sp = []
  t.forEach((_, idx) => {
    const C = c[idx]
    const High = h[idx]
    const Low = l[idx]
    const lastClose = c[idx - 1] || 0
    const Hi = Math.max(High, lastClose)
    const Li = Math.min(Low, lastClose)
    bp.push(C - Li)
    sp.push(Hi - C)
  })

  // bpavg = EMA(bp,80);
  // spavg = EMA(sp,80);
  const bpavg = ema(bp, 80)
  const spavg = ema(sp, 80)

  // nbp = bp/bpavg;
  // nsp = sp/spavg;
  const nbp = []
  const nsp = []
  bp.forEach((_bp, idx) => {
    const _bpavg = bpavg[idx - 79]
    nbp.push(_bp / _bpavg)
  })
  sp.forEach((_sp, idx) => {
    const _spavg = spavg[idx - 79]
    nsp.push(_sp / _spavg)
  })

  // Varg = EMA(V,80);
  const Varg = ema(v, 80)

  // nv = V/Varg;
  const nv = []
  v.forEach((_v, idx) => {
    const _varg = Varg[idx - 79]
    nv.push(_v / _varg)
  })

  // nbfraw = nbp * nv;
  // nsfraw = nsp * nv;
  const nbfraw = []
  const nsfraw = []
  nv.forEach((_nv, idx) => {
    const _nbfraw = nbp[idx] * _nv
    nbfraw.push(Number.isNaN(_nbfraw) ? 0 : _nbfraw)

    const _nsfraw = nsp[idx] * _nv
    nsfraw.push(Number.isNaN(_nsfraw) ? 0 : _nsfraw)
  })

  // nbf = EMA(nbfraw,20);
  // nsf = EMA(nsfraw,20);
  const nbf = ema(nbfraw, 20)
  const nsf = ema(nsfraw, 20)

  const nbfData = []
  const nsfData = []

  // diff = nbf-nsf;
  const diffData = []
  t.forEach((time, idx) => {
    const _nbf = nbf[idx - 19]
    const _nsf = nsf[idx - 19]
    const _diff = _nbf - _nsf
    time += timezoneOffset
    nbfData.push({ time, value: _nbf })
    nsfData.push({ time, value: _nsf })
    diffData.push({ time, value: _diff })
  })

  currentData = {
    candle: candleData,
    nbf: nbfData,
    nsf: nsfData,
    diff: diffData,
  }

  return currentData
}

export default {
  components: {
    ChartValues,
  },
  props: {
    symbol: {
      type: String,
      default: '',
    },
    resolution: {
      type: String,
      default: '1D',
    },
  },
  data() {
    return {
      chartNames,
      currentTimeIndex: 0,
      sliderValue: [35, 70],
      sliderValueBeforeZoom: [35, 70],
      zoomBtnMarginTop: [0, 0, 0],
      moveBtnMarginTop: [0, 0, 0],
      isZoomIn: false,
      rawData: {},
    }
  },
  computed: {
    chartRatios() {
      const value = this.sliderValue

      return [
        100 - value[1],
        value[1] - value[0],
        value[0],
      ]
    },
    currentDataPoint() {
      return this.getDataPoint(this.currentTimeIndex)
    },
    prevDataPoint() {
      return this.getDataPoint(this.currentTimeIndex - 1)
    },
  },

  watch: {
    symbol() {
      this.resetZoom()
    },
    resolution(value) {
      this.resetZoom()
      const timeVisible = value !== '1D'
      charts.forEach(chart => chart && chart.applyOptions({
        timeScale: {
          timeVisible,
        },
      }))
    },
    rawData(data, oldData) {
      const computed = computeData(data)
      Object.keys(series).forEach(key => {
        series[key].setData(computed[key])
      })

      // First load
      if (!oldData.t) this.resetZoom()
    },

    sliderValue() {
      this.setChartsHeight()
    },
  },

  mounted() {
    this.initCharts()

    setTimeout(() => {
      this.setChartsHeight()
      this.setChartsWidth()
    }, 100)

    window.addEventListener('resize', () => {
      this.setChartsHeight()
      this.setChartsWidth()
    })

    window.addEventListener('keydown', ({ key }) => {
      if (!this.currentTimeIndex) return
      if (key !== 'ArrowLeft' && key !== 'ArrowRight') return

      const newIndex = this.currentTimeIndex + (key === 'ArrowLeft' ? -1 : 1)
      const newTime = currentData.candle[newIndex]?.time || 0
      if (!newTime) return

      charts.forEach((chart, id) => {
        chart.setCrosshairPosition(0, newTime, getSeriesFromChartId(id))
      })

      this.currentTimeIndex = newIndex
    })
  },

  methods: {
    initCharts() {
      const container = this.$refs.chartContainer
      container.appendChild(watermark)
      const len = charts.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        const options = chartOptions
        options.timeScale.visible = (i === 2)
        charts[i] = createChart(container, options)
      }

      charts.forEach((chart, idx) => {
        switch (chartNames[idx]) {
          case 'candle':
            series.candle = chart.addCandlestickSeries(candleSeriesOptions)
            break
          case 'money':
            series.nbf = chart.addLineSeries(nbfSeriesOptions)
            series.nsf = chart.addLineSeries(nsfSeriesOptions)
            break
          case 'diff':
            series.diff = chart.addBaselineSeries(diffSeriesOptions)
            break

          default:
            break
        }
      })
      series.diff.createPriceLine(diffLineOptions)

      charts.forEach((chart, id) => {
        if (chart) {
          chart.timeScale().subscribeVisibleLogicalRangeChange(syncVisible.bind(this, id))
          chart.subscribeCrosshairMove(syncCrosshair.bind(this, id))
        }
      })
    },
    move(id, moveUp) {
      const swapId = moveUp ? id - 1 : id + 1
      if (swapId < 0 || swapId >= charts.length) return

      const currentRange = charts[0].timeScale().getVisibleLogicalRange()

      charts.forEach(chart => chart && chart.remove());

      [chartNames[id], chartNames[swapId]] = [chartNames[swapId], chartNames[id]]

      this.initCharts()
      this.setChartsHeight()
      this.setChartsWidth()

      Object.keys(series).forEach(key => {
        series[key].setData(currentData[key])
      })

      charts[0].timeScale().setVisibleLogicalRange(currentRange)
    },
    setRawData(data, resetData) {
      if (!data) return
      if (resetData) {
        this.rawData = data
      } else {
        const dt = { ...this.rawData }
        dt.t = [...data.t, ...dt.t]
        dt.c = [...data.c, ...dt.c]
        dt.o = [...data.o, ...dt.o]
        dt.h = [...data.h, ...dt.h]
        dt.l = [...data.l, ...dt.l]
        dt.v = [...data.v, ...dt.v]
        this.rawData = dt
      }
    },
    pushData(input) {
      if (!input) return
      const dt = { ...this.rawData }

      let { resolution } = this

      let time = 0

      if (resolution.includes('D')) {
        resolution = 1440 // 1 day in minutes
      } else if (resolution.includes('W')) {
        resolution = 10080 // 1 week in minutes
      }
      const coeff = resolution * 60 // 1 minutes
      time = Math.floor(input.t / coeff) * coeff

      if (Number.isNaN(+resolution)) { // resolution = 1D, W, M, Y
        const d = new Date(input.t * 1000)
        d.setHours(0, 0, 0, 0)
        time = d.getTime() / 1000
      }

      const idx = timeIndexes[time + timezoneOffset]
      const times = Object.keys(timeIndexes)
      const last = times[times.length - 1]

      if (!idx) {
        if (time < last - timezoneOffset) return
        dt.t.push(time)
        dt.c.push(input.c)
        dt.o.push(input.o)
        dt.h.push(input.h)
        dt.l.push(input.l)
        dt.v.push(input.v)
      } else {
        // dt.o[idx] = input.o
        if (input.c < dt.l[idx]) {
          dt.l[idx] = input.c
        } else if (input.c > dt.h[idx]) {
          dt.h[idx] = input.c
        }
        dt.c[idx] = input.c
        dt.v[idx] += input.v
      }

      this.rawData = dt
    },
    getDataPoint(index) {
      return {
        candle: currentData?.candle[index],
        money: {
          nbf: currentData?.nbf[index],
          nsf: currentData?.nsf[index],
        },
        diff: currentData?.diff[index],
      }
    },
    setChartsHeight() {
      const { height } = this.$refs.slider.$el.querySelector('.v-slider').getBoundingClientRect()
      const ratios = this.chartRatios.map(v => v / 100)

      this.zoomBtnMarginTop = [
        0,
        `${height * ratios[0]}px`,
        `${height * (1 - ratios[2])}px`,
      ]
      const containerHeight = 100
      this.moveBtnMarginTop = [
        `${(height * ratios[0] - containerHeight) / 2}px`,
        `${(height * ratios[1] - containerHeight) / 2 + height * ratios[0]}px`,
        `${(height * ratios[2] - containerHeight) / 2 + height * (ratios[0] + ratios[1])}px`,
      ]
      charts.forEach((chart, idx) => {
        if (chart) {
          chart.applyOptions({
            height: height * ratios[idx],
          })
        }
      })
    },
    setChartsWidth() {
      const { width } = this.$refs.chartContainer.getBoundingClientRect()
      charts.forEach(chart => {
        if (chart) {
          chart.applyOptions({ width })
        }
      })
    },
    resetZoom() {
      const length = this.rawData.t?.length
      if (length) {
        if (charts[0]) {
          charts[0].timeScale().setVisibleLogicalRange({ from: length - 50, to: length })
        }
      }
    },
    zoomOut() {
      this.isZoomIn = false
      this.sliderValue = this.sliderValueBeforeZoom
      const count = charts.length
      charts.forEach((chart, idx) => {
        if (chart) {
          const visible = (idx === count - 1)
          chart.applyOptions({ timeScale: { visible } })
        }
      })
    },
    zoomIn(chartId) {
      this.isZoomIn = true
      this.sliderValueBeforeZoom = this.sliderValue
      charts.forEach((chart, idx) => {
        if (chart) {
          if (chartId !== idx) chart.applyOptions({ height: 1 })
          else chart.applyOptions({ timeScale: { visible: true } })
        }
      })
      const valueMap = {
        0: [0, 0],
        1: [0, 100],
        2: [100, 100],
      }
      this.sliderValue = valueMap[chartId] ?? this.sliderValue
    },
  },
}
</script>

<style scoped>
  .lw-chart {
    height: 100%;
    width: calc(100% - 13px);
  }

  .size-slider {
    width: 13px;
  }

  .btn-zoom {
    position: absolute;
    right: 95px;
    top: 85px;
    z-index: 5;
  }

  .container-move {
    height: 100px;
    position: absolute;
    left: 30px;
    z-index: 5;
  }
  .container-move.down {
    margin-top: 10px;
    top: 250px;
  }

  ::v-deep .tv-lightweight-charts div {
    border-top: 1px solid black
  }

  ::v-deep .v-slider {
    height: 75vh;
    margin: 0;
  }

  ::v-deep .v-slider__thumb:hover {
    cursor: ns-resize;
  }

  ::v-deep .v-slider__thumb::before {
    width: 0;
    height: 0;
  }

  ::v-deep .v-slider__track-background {
    opacity: 1;
  }

  ::v-deep .v-slider--vertical .v-slider__track-container {
    width: 1px;
  }
</style>
